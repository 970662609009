import { Download, ErrorOutline, VisibilityOutlined } from '@mui/icons-material';
import { Box, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import { DocumentDto, fetchDocument } from '../../apis/document';
import { InvoiceSummary } from '../../apis/invoices';
import { useState } from 'react';

// @ts-ignore
import pdfLogo from '../../images/pdf.svg';
import {
    FetchState,
    FetchStateType,
    loadingState,
    pendingState,
    isError,
    isLoading,
    isSuccess,
} from '../../hooks/useFetch';
import { LoadingButton } from '../../components/LoadingButton';

type DocumentItemProps = {
    invoice: InvoiceSummary;
    simfuniDocument: DocumentDto;
};

type LoadedDocument = {
    url: string;
    size: number;
};

enum FileAction {
    DOWNLOAD,
    VIEW,
}

const processAction = (action: FileAction, fileUrl: string, simfuniDocument: DocumentDto) => {
    if (action === FileAction.VIEW) {
        const pdfWindow = window.open();
        pdfWindow!.location.href = fileUrl;
    } else {
        const anchorElement = document.createElement('a');
        anchorElement.href = fileUrl;
        anchorElement.setAttribute('download', simfuniDocument.name);
        anchorElement.target = '_blank';
        document.body.appendChild(anchorElement);
        anchorElement.click();
        anchorElement?.parentNode?.removeChild(anchorElement);
    }
};

export default function DocumentItem({ invoice, simfuniDocument }: Readonly<DocumentItemProps>) {
    const [fetchState, setFetchState] = useState<FetchState<LoadedDocument>>(pendingState);
    const [selectedAction, setSelectedAction] = useState<FileAction>();

    const loadDocument = (action: FileAction) => {
        setSelectedAction(action);

        if (isSuccess(fetchState)) {
            processAction(action, fetchState.value.url, simfuniDocument);
            return;
        }

        setFetchState(loadingState);

        fetchDocument(invoice.uuid, simfuniDocument.uuid)
            .then((blob) => {
                if (blob) {
                    const fileUrl = URL.createObjectURL(blob);
                    setFetchState({
                        type: FetchStateType.SUCCESS,
                        value: {
                            url: fileUrl,
                            size: blob.size,
                        },
                    });
                    processAction(action, fileUrl, simfuniDocument);
                } else {
                    setFetchState({
                        type: FetchStateType.ERROR,
                        error: new Error('File unavailable'),
                    });
                }
            })
            .catch((error) => {
                setFetchState({
                    type: FetchStateType.ERROR,
                    error,
                });
            });
    };

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', flexDirection: { sm: 'row', xs: 'column' } }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                            <img src={pdfLogo} alt='pdf logo' width='24px' />
                            <Typography variant='body1'>{simfuniDocument.name}</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', '& hr': { mx: 1 } }}>
                        <LoadingButton
                            variant='text'
                            endIcon={<VisibilityOutlined />}
                            onClick={() => loadDocument(FileAction.VIEW)}
                            loading={isLoading(fetchState) && selectedAction === FileAction.VIEW}
                        >
                            View
                        </LoadingButton>
                        <Divider orientation='vertical' flexItem />
                        <LoadingButton
                            variant='text'
                            endIcon={<Download />}
                            onClick={() => loadDocument(FileAction.DOWNLOAD)}
                            loading={isLoading(fetchState) && selectedAction === FileAction.DOWNLOAD}
                        >
                            Download
                        </LoadingButton>
                        {isError(fetchState) && (
                            <>
                                <Typography variant='body2'>Failed to load</Typography>
                                <Tooltip
                                    title='Something went wrong, please try again later.'
                                    sx={{ mt: -1 }}
                                    open={true}
                                    onClose={() => setFetchState(pendingState)}
                                >
                                    <IconButton onClick={() => setFetchState(pendingState)}>
                                        <ErrorOutline fontSize='small' color='error' />
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}
                    </Box>
                </Box>
            </Box>
            <Divider />
        </>
    );
}
