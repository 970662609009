import { Alert, Box, Button, Paper, Typography } from '@mui/material';
import {
    isSuccessfulPaymentResponse,
    makeOnDemandPayment,
    queryPaymentResult,
    PaymentResponse,
    PaymentStatus,
} from '../../../apis/payment';
import { InvoiceSummary, Loan } from '../../../apis/invoices';
import { useAppSelector } from '../../../store/reducer/Hooks';
import { RootState } from '../../../store/Store';
import { getClassCodeDescription, getPaymentAmount } from '../../../util/invoiceUtils';
import StepCard from '../../../components/StepCard';
import PaymentMethodDetails from '../PaymentMethodDetails';
import { useRef, useState } from 'react';
import { PaymentMethod } from '../../../apis/paymentMethodApi';
import { LoadingButton } from '../../../components/LoadingButton';

type Props = {
    cancel: () => void;
    setUpdatedModels: (invoice: InvoiceSummary, loan: Loan) => void;
    paymentMethod: PaymentMethod;
    title: string;
    description: string;
};

const MAX_POLLS = 120;
const GENERIC_ERROR = 'Something went wrong, please try again.';

export default function PaymentStep({ cancel, setUpdatedModels, paymentMethod, title, description }: Readonly<Props>) {
    const pollingRef = useRef(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const { value: invoice } = useAppSelector((root: RootState) => root.persistedInvoiceReducer);
    const paymentAmount = currencyFormatter.format(getPaymentAmount(invoice));
    const firstPolicyClasscode = getClassCodeDescription(invoice.portfolio.policies[0]);
    const fee = invoice.nextPayment?.feeAmount ?? 0;

    const makePayment = (e: React.FormEvent) => {
        e.preventDefault();

        setError(null);
        setLoading(true);
        makeOnDemandPayment(invoice.uuid)
            .then(handleResponse)
            .catch(() => handleError());
    };

    const handleError = (error?: string) => {
        pollingRef.current = 0;
        setLoading(false);
        setError(error ?? GENERIC_ERROR);
    };

    const handleResponse = (res: PaymentResponse) => {
        if (isSuccessfulPaymentResponse(res)) {
            setLoading(false);
            setUpdatedModels(res.invoice, res.loan);
            return;
        }

        if (res.status === PaymentStatus.PROCESSING) {
            ++pollingRef.current;
            if (pollingRef.current > MAX_POLLS) {
                return handleError(
                    'The payment response is taking too long to load. Please come back and try again later.'
                );
            }

            setTimeout(() => {
                queryPaymentResult(invoice.uuid, res.uuid).then(handleResponse);
            }, 500);
            return;
        }

        if (res.status === PaymentStatus.PAYMENT_FAILED) {
            return handleError('Payment failed. Please change your payment method and try again.');
        }

        return handleError();
    };

    return (
        <StepCard>
            <form onSubmit={makePayment}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {error != null && <Alert severity='error'>{error}</Alert>}

                    <Typography variant='h5' component='h2'>
                        {title}
                    </Typography>

                    <Box>
                        <Typography sx={{ fontWeight: 300, fontSize: '36px', lineHeight: '60px' }}>
                            {paymentAmount}
                        </Typography>
                        <Typography variant='body1'>{description}</Typography>
                        <Typography variant='caption'>
                            for payment bill #{invoice.number}
                            {firstPolicyClasscode ? ` (${firstPolicyClasscode})` : ''}
                        </Typography>
                    </Box>
                    <Paper variant='outlined' sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Typography variant='h6' component='h3'>
                            Payment method
                        </Typography>
                        <PaymentMethodDetails data={paymentMethod} reversed />
                    </Paper>

                    <Typography variant='caption'>
                        You can change the payment method from the insurance details page.
                    </Typography>

                    <Box>
                        {fee > 0 && (
                            <Typography variant='caption'>
                                The payment total includes a fee of {currencyFormatter.format(fee)}.<br />
                            </Typography>
                        )}
                        <Typography variant='caption'>
                            The payment will be be deducted within the next 24 hours. Ensure that you have{' '}
                            {paymentAmount} available.
                        </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <LoadingButton loading={loading} variant='contained' color='primary' type='submit' size='large'>
                            Pay {paymentAmount}
                        </LoadingButton>
                        <Button disabled={loading} onClick={cancel} variant='text' color='primary' size='large'>
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </form>
        </StepCard>
    );
}

const currencyFormatter = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
