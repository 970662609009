import {Box} from '@mui/material';
import {BusinessEntityType, Client, isBusiness, isIndividual, isTrust} from '../../apis/myProfile';
import moment from 'moment';
import {DATE_FRIENDLY} from '../../util/dateUtils';
import FormattedAddress from '../../components/FormattedAddress';
import {isEmpty} from 'lodash';
import FormattedAuthorizedPerson from '../../components/FormattedAuthorizedPerson';
import DisplayDetail from './DisplayDetail';

export default ({data}: { data: Client }) => {
    return (
        <Box sx={{mt: 2, mx: 1, mb: 12, display: 'flex', flexDirection: 'column', gap: 1}}>
            {isIndividual(data) && (
                <>
                    <DisplayDetail title='Name' description={data.displayName} />
                    {data.personalDetails.dateOfBirth && (
                        <DisplayDetail
                            title='Date of birth'
                            description={moment(data.personalDetails.dateOfBirth).format(DATE_FRIENDLY)}
                        />
                    )}
                </>
            )}

            {isBusiness(data) && (
                <>
                    {!isEmpty(data.tradingName) && (
                        <DisplayDetail title='Trading name' description={data.tradingName!} />
                    )}
                    <DisplayDetail title='Business name' description={data.businessName} />
                    <DisplayDetail title='Business type' description={getReadableBusinessType(data.entityType)} />
                    <DisplayDetail title='Business number' description={data.businessNumber} />
                    <DisplayDetail title='Companies office number' description={data.companiesOfficeNumber} />
                </>
            )}

            {isTrust(data) && (
                <>
                    <DisplayDetail title='Trust name' description={data.trustName} />
                    <DisplayDetail title='Business number' description={data.businessNumber} />
                </>
            )}

            {(isTrust(data) || isBusiness(data)) && (
                <DisplayDetail
                    title='Authorized individual'
                    description={<FormattedAuthorizedPerson authorizedIndividual={data.authorizedIndividual} />}
                />
            )}

            <DisplayDetail title='Email address' description={data.contactDetails.email.address} />
            <DisplayDetail title='Mobile number' description={data.contactDetails.preferredPhoneNumber.number} />
            {data.contactDetails.preferredAddress && (
                <DisplayDetail
                    title='Address'
                    description={<FormattedAddress address={data.contactDetails.preferredAddress} />}
                />
            )}
        </Box>
    );
};

const getReadableBusinessType = (entityType: BusinessEntityType): string => {
    switch (entityType) {
        case BusinessEntityType.LIMITED_LIABILITY:
            return 'Limited Liability';
        case BusinessEntityType.REGISTERED_COMPANY:
            return 'Registered Company';
        case BusinessEntityType.SOLE_TRADER:
            return 'Sole Trader';
        default:
            return entityType;
    }
};
