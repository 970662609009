import { Box, Typography } from '@mui/material';
import { useAppSelector } from '../../store/reducer/Hooks';
import { RootState } from '../../store/Store';
import { fetchAvailableDocuments, PagedDocumentDto } from '../../apis/document';
import { useFetch } from '../../hooks/useFetch';
import DocumentItem from './DocumentItem';
import { FetchWrapper } from '../../components/FetchWrapper';
import { useCallback } from 'react';

export default function DocumentDetails() {
    const { value: invoice } = useAppSelector((state: RootState) => state.persistedInvoiceReducer);

    const documentState = useFetch(() => fetchAvailableDocuments(invoice.uuid));

    const Documents = useCallback(({ data }: { data: PagedDocumentDto }) => {
        if (data.records.length === 0) {
            return <Typography variant='body1'>There are no documents available</Typography>;
        }

        return (
            <>
                {data.records.map((documentDto) => (
                    <DocumentItem invoice={invoice} simfuniDocument={documentDto} key={documentDto.uuid} />
                ))}
            </>
        );
    }, []);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant='h5' component='h2'>
                Documents
            </Typography>
            <FetchWrapper state={documentState} SuccessComponent={Documents} />
        </Box>
    );
}
