import axios from 'axios';

export type DocumentDto = {
    contentType: string;
    name: string;
    uuid: string;
};

export type PagedDocumentDto = {
    records: DocumentDto[];
};

export const fetchDocument = async (
    invoiceIdentifier: string,
    documentIdentifier: string
): Promise<Blob | undefined> => {
    const url = new URL(
        `${process.env.REACT_APP_CLIENT_PORTAL_HOST}/invoices/${invoiceIdentifier}/documents/${documentIdentifier}`
    );
    return await axios.get(url.href, { responseType: 'blob' }).then(({ data }) => data);
};

export const fetchAvailableDocuments = async (invoiceIdentifier: string): Promise<PagedDocumentDto> => {
    return await axios
        .get(`${process.env.REACT_APP_CLIENT_PORTAL_HOST}/invoices/${invoiceIdentifier}/documents`)
        .then(({ data }) => data);
};
